import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:8080',
  // baseURL: 'http://ddanzit.donnert.net',
  headers: {
    ...axios.defaults.headers,
    'Content-type': 'application/json',
  }
  // headers: {
  //   // 'Content-type': 'application/json',
  //   // 'Content-type': 'application/json',
  //   'Cache-Control': 'no-cache',
  // 'Origin': 'http://localhost:3000',
  //   Pragma: 'no-cache',
  //   Expires: '0',
  // },
});

// instance.interceptors.request.use(
//   config => {
//     if (!config?.headers?.authorization) {
//       const token = `Bearer ${window.localStorage.getItem('token')}` || 0;
//       if (token && token.length > 0 && config.headers) {
//         // eslint-disable-next-line no-param-reassign
//         config.headers.authorization = token;
//       } else {
//         window.location.href = '/login';
//       }
//     }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   },
// );

// instance.interceptors.response.use(
//   res => res,
//   error => {
//     if (error.response && error.response.data) {
//       const { statusCode } = error.response.data;
//       // if(statusCode === 401){
//       //   window.localStorage.removeItem('token');
//       //   window.location.href = '/login';
//       // }
//     }
//     return Promise.reject(error);
//   },
// );

export default instance;
