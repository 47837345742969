import React from 'react';
import './App.css';
import Main from './Components/Main';
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@emotion/react";
import { ColorModeContext } from './common/color-context';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

function App() {
  const [mode, setMode] = React.useState<'light' | 'dark'>((localStorage.getItem("uiMode") as 'light' | 'dark') || 'light');
  const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => {
              const currentMode = prevMode === 'light' ? 'dark' : 'light'
              localStorage.setItem('uiMode', currentMode)
              return currentMode
          });
        },
      }),
      [],
  );

  const theme = React.useMemo(
      () =>
          createTheme({
            palette: {
              mode,
            },
          }),
      [mode],
  );
  return (
    <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <Main/>
            <ToastContainer autoClose={1000} />
          </ThemeProvider>
      </ColorModeContext.Provider>
  );
}

export default App;
