import {createTheme, responsiveFontSizes, ThemeProvider} from '@mui/material/styles';
import {BrowserView, MobileView, isBrowser, isMobile} from 'react-device-detect';
import CssBaseline from '@mui/material/CssBaseline';
import * as Scroll from 'react-scroll';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Paper,
    Box,
    Button,
    Link,
    TableContainer,
    TableHead,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField, useMediaQuery, IconButton, useTheme, DialogTitle, DialogContent, Dialog, DialogContentText, DialogActions
} from '@mui/material';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import React, {useCallback, useRef, useState} from 'react';
import apiInstance from '../common/ApiInstance';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ToggleButton from '@mui/material/ToggleButton';
import ClearIcon from '@mui/icons-material/Clear';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {ColorModeContext} from '../common/color-context';
import ChatLogin from "./ChatLogin";
import {ChatProps} from "./ChatLogin";

const scroll = Scroll.animateScroll

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center"
                    sx={{
                        mt: 1
                    }}
        >
            {'Copyright © '}
            <Link color="inherit" href="http://donnert.net/">
                donnert.net
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const sites = [
    {"id": 1, "code": "PPOMPPU", "name": "뽐뿌", "active": false},
    {"id": 2, "code": "CLIEN", "name": "클리앙", "active": false},
    {"id": 3, "code": "HUMORUNIV", "name": "웃긴대학", "active": false},
    {"id": 4, "code": "FMKOREA", "name": "펨코", "active": false},
    {"id": 5, "code": "DDANZI", "name": "딴지", "active": false},
    {"id": 6, "code": "INSTIZ", "name": "인스티즈", "active": false},
    {"id": 7, "code": "BOBAE", "name": "보배드림", "active": false},
    {"id": 8, "code": "MLBPARK", "name": "엠팍", "active": false},
    {"id": 9, "code": "THEQOO", "name": "더쿠", "active": false},
    {"id": 10, "code": "RULIWEB", "name": "루리웹", "active": false},
    {"id": 11, "code": "DOGDRIP", "name": "개드립", "active": false},
    {"id": 12, "code": "TODAYHUMOR", "name": "오유", "active": false},
    {"id": 13, "code": "DCINSIDE", "name": "디씨", "active": false},
]

let startTime: Date;

function Main() {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [chatUsername, setChatUsername] = React.useState<string>('ss')

    const [list, setList] = React.useState<any[]>([])
    const [page, setPage] = React.useState(0)
    const [title, setTitle] = React.useState<string>('')
    // const [activeSites, setActiveSites] = React.useState<string[]>(sites.map(it => { return it.code}))
    const [activeSites, setActiveSites] = React.useState<string[]>([])
    const [allSite, setAllSite] = React.useState<boolean>(false)
    const inputRef = React.useRef<HTMLInputElement>();
    const [open, setOpen] = React.useState(false);
    const [secretCode, setSecretCode] = React.useState<string | null>();
    const [newChat, setNewChat] = useState<boolean>(true);
    // const [startTime, setStartTime] = React.useState<Date>();

    const chatRef = useRef<ChatProps>(null);

    React.useEffect(() => {
        const activeStorageFromLocalStorage = localStorage.getItem("activeSites")
        if (activeStorageFromLocalStorage !== undefined && activeStorageFromLocalStorage) {
            setActiveSites(JSON.parse(activeStorageFromLocalStorage))
        }

        const allSiteFromLocalStorage = localStorage.getItem("allSite")
        if (allSiteFromLocalStorage !== undefined && allSiteFromLocalStorage) {
            setAllSite(allSiteFromLocalStorage === 'true')
        }

        // secret
        const secretDate = localStorage.getItem("secretDate")
        const today = new Date().toISOString().substring(0, 10);
        if (secretDate === today) {
            setSecretCode(localStorage.getItem("secretCode"))
        }
    }, [])

    React.useEffect(() => {
        if (title === 'nnm!' || title === 'NNM!' || title === 'ㅜㅜㅡ!' || title === 'Nnm!') {
            setNewChat(true)
            const today = new Date().toISOString().substring(0, 10);
            localStorage.setItem("secretDate", today)
            localStorage.setItem("secretCode", "secret")
            setSecretCode("secret")
            handleClickOpenDialog()
            startTimer()
            return
        }
        if (title === 'mmn!' || title === 'MMN!' || title === 'ㅡㅡㅜ!' || title === 'Nmm!') {
            // setNewChat(false)
            const today = new Date().toISOString().substring(0, 10);
            localStorage.setItem("secretDate", today)
            localStorage.setItem("secretCode", "secret")
            setSecretCode("secret")
            handleClickOpenDialog()
            startTimer()
            return
        }

        if (title === 'ㅁㅁㅁ!@' || title === 'aaa!@') {
            setOpen(true)
            apiInstance.get(`/api/list?sites=${activeSites}&page=${page}&title=&exclude=false`).then(it => {
                setList(it.data.content)
                setTimeout(() => setTitle(''), 1000)
            })
        } else {
            apiInstance.get(`/api/list?sites=${activeSites}&page=${page}&title=${title}&exclude=false`).then(it => {
                setList(it.data.content)
            })
        }
    }, [page, activeSites, title])

    const startTimer = () => {
        startTime = new Date()
        const closeTerm = 600

        setTimeout(function () {
            const now = new Date().getTime()
            const start = startTime.getTime()
            const elapsedTIme = now - start
            if (elapsedTIme >= closeTerm * 1000) {
                handleCloseDialog()
            }
        }, closeTerm * 1000)
    }

    const openDialog = () => {
        // apiInstance.get(`https://api.prowlapp.com/publicapi/add?apikey=40c48320c21e8b8e9179a12c0d41e38aa6b613ad&application=omoomo&event=slow query&description=ssfire`)
        // apiInstance.post(`https://hooks.slack.com/services/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`, {text: 'slow query'})


        const prowlUrl = "https://api.prowlapp.com/publicapi/add?apikey=40c48320c21e8b8e9179a12c0d41e38aa6b613ad&application=OMOOMO&description=SlowQuery"
        apiInstance.get(prowlUrl)
        const url = 'https://hooks.slack.com/services/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'
        const data = {
            "text": "slow query(" + newChat + ")",
        }
        apiInstance.post(url, JSON.stringify(data), {
            withCredentials: false,
            transformRequest: [(data, headers) => {
                headers.delete("Content-Type")
                // delete headers.post["Content-Type"]
                return data
            }]
        })
        setOpen(true);
        setTimeout(() => setTitle(''), 1000)
    }

    const handleClickCopyright = () => {
        const hour = new Date().getHours()
        if (hour >= 2 && hour <= 18) {
            startTimer()
            handleClickOpenDialog()
        }
    }

    const handleClickOpenDialog = () => {
        const secretDate = localStorage.getItem("secretDate")
        const secretCode = localStorage.getItem("secretCode")
        const today = new Date().toISOString().substring(0, 10)
        if (!secretCode || secretDate !== today) {
            localStorage.removeItem("secretCode")
            localStorage.removeItem("secretDate")
            return
        }
        openDialog()

    };

    const handleCloseDialog = () => {
        setOpen(false);
        chatRef.current?.disconnect()
    };

    const handleClick = (url: string) => {
        window.open(url, '');
    }

    const handleNextButton = () => {
        setPage(page + 1)
        scroll.scrollToTop()
    }

    const handlePrevButton = () => {
        setPage(page - 1)
        scroll.scrollToTop()
    }

    const handleToggleSite = (
        event: React.MouseEvent<HTMLElement>,
        siteCode: string,
    ) => {
        const newActiveSites = [...activeSites]
        if (!newActiveSites.includes(siteCode)) {
            newActiveSites.push(siteCode);
        } else {
            newActiveSites.splice(newActiveSites.indexOf(siteCode), 1);
        }

        setActiveSites(newActiveSites)
        localStorage.setItem("activeSites", JSON.stringify(newActiveSites))
    };

    const handleAllSite = () => {
        let newActiveSites: string[] = []
        if (!allSite) {
            newActiveSites = sites.map(it => {
                return it.code
            })
        }

        localStorage.setItem("allSite", String(!allSite))
        setAllSite(!allSite)
        localStorage.setItem("activeSites", JSON.stringify(newActiveSites))
        setActiveSites(newActiveSites)
    }

    // const handleKeyPress = useCallback((event: KeyboardEvent) => {
    //     if(event.key==='s' || event.key === 'S') {
    //         handlePrevButton()
    //     }
    //     if(event.key==='F' || event.key === 'f') {
    //         handleNextButton()
    //     }
    //     if(event.key==='r' || event.key === 'R') {
    //         window.location.href='/'
    //     }
    // }, []);
    // React.useEffect(() => {
    //     // attach the event listener
    //     document.addEventListener('keydown', handleKeyPress);
    //
    //     // remove the event listener
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, [handleKeyPress]);

    return (
        <>
            {/* <ThemeProvider theme={theme}>*/}
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    py: 1,
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                {/*<Toolbar>*/}
                <Toolbar sx={{padding: {xs: 1, sm: 1, md: 0, lg: 0, xl: 0}}}>
                    <Container component="main"
                               disableGutters={useMediaQuery(theme.breakpoints.only('xs'))}
                    >
                        <Grid container>
                            <Grid xs>
                                <Typography variant="h6" color="inherit" noWrap
                                            onClick={() => {
                                                window.location.href = "/"
                                            }}
                                            style={{cursor: 'pointer'}}
                                >
                                    DDanzit-딴짓
                                </Typography>
                            </Grid>
                            <Grid xs={1}>
                                &nbsp;
                            </Grid>
                            <Grid xs textAlign={'right'}>
                                <IconButton sx={{ml: 1}} onClick={colorMode.toggleColorMode} color="inherit">
                                    {theme.palette.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            {
                                sites.map((it: any) => {
                                    return (<Grid key={it.code}>
                                        <ToggleButton
                                            // color='info'
                                            // color='secondary'
                                            // color='error'
                                            // color='success'
                                            color='warning'
                                            size='small' selected={activeSites.includes(it.code)} sx={{height: 30}}
                                            value={it.code} onClick={handleToggleSite}>
                                            {it.name}
                                        </ToggleButton>
                                    </Grid>)
                                })
                            }
                            <Grid>
                                <ToggleButton
                                    color='success'
                                    size='small' selected={allSite} sx={{height: 30}} value={'all'}
                                    onClick={handleAllSite}>
                                    전체
                                </ToggleButton>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
            <Container component="main"
                       sx={{mb: 4}}
                       disableGutters={useMediaQuery(theme.breakpoints.only('xs'))}
            >
                <Paper elevation={0} sx={{my: {xs: 1, md: 2}, p: 0}}>
                    <Box sx={{flexGrow: 1, pb: 1, px: 0.5}}>
                        <Grid container spacing={1}>
                            <Grid xs>
                                <MobileView>
                                    <Button disabled={page === 0} color='inherit' size='medium' variant="outlined"
                                            onClick={handlePrevButton}
                                            style={{height: '40px'}}
                                            fullWidth
                                    >
                                        <ArrowBackIosIcon style={{marginLeft: '5'}}/>
                                    </Button>
                                </MobileView>
                                <BrowserView style={{width: '100%'}}> <Button disabled={page === 0} color='inherit'
                                                                              size='medium' variant="outlined"
                                                                              startIcon={<ArrowBackIosIcon/>}
                                                                              onClick={handlePrevButton}
                                                                              style={{height: '40px'}}
                                                                              fullWidth
                                >{!isMobile && 'PREV'}</Button>
                                </BrowserView>
                            </Grid>
                            <Grid xs={7}>
                                <TextField
                                    id="standard-name"
                                    size={'small'}
                                    fullWidth
                                    inputRef={inputRef}
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                    InputProps={{
                                        endAdornment: <ClearIcon onClick={() => {
                                            setTitle('');
                                            inputRef && inputRef.current && inputRef.current.focus();
                                        }}
                                                                 style={{cursor: 'pointer'}}
                                        />
                                    }}
                                    onKeyDown={e => {
                                        e.stopPropagation()
                                    }}
                                />
                            </Grid>
                            <Grid xs>
                                <MobileView>
                                    <Button color='inherit' size='medium' variant="outlined" onClick={handleNextButton}
                                            style={{height: '40px'}}
                                            fullWidth
                                    >
                                        <ArrowForwardIosIcon/>
                                    </Button>
                                </MobileView>
                                <BrowserView style={{width: '100%'}}>
                                    <Button color='inherit' size='medium' variant="outlined"
                                            startIcon={<ArrowForwardIosIcon/>} onClick={handleNextButton}
                                            style={{height: '40px'}}
                                            fullWidth
                                    >{'PREV'}</Button>
                                </BrowserView>
                            </Grid>
                        </Grid>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size='small' aria-label="simple table" style={{tableLayout: 'fixed'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{px: 1, width: '15%'}} align="center">사이트</TableCell>
                                    <TableCell sx={{px: 1}} align="center">제목</TableCell>
                                    {!isMobile && (
                                        <TableCell align="center">날짜</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((row) => (
                                    <TableRow
                                        key={row.articleId}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        onClick={() => {
                                            handleClick(row.host + row.href)
                                        }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <TableCell sx={{px: 0, minWidth: 55}} align="center">
                                            <Typography variant='caption' noWrap>
                                                {row.siteName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{px: 0, pr: 1}}>
                                            {/*<Typography sx={{*/}
                                            {/*    overflow: "hidden",*/}
                                            {/*    textOverflow: "ellipsis"*/}
                                            {/*}}>*/}
                                            <Typography variant={'body2'} noWrap>
                                                {row.title}
                                            </Typography>

                                        </TableCell>
                                        {!isMobile && (
                                            <TableCell align="center">
                                                {row.date}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{flexGrow: 1, pt: 1, px: 0.5}}>
                        <Grid container spacing={1}>
                            <Grid xs>
                                <MobileView>
                                    <Button disabled={page === 0} color='inherit' size='medium' variant="outlined"
                                            onClick={handlePrevButton}
                                            style={{height: '40px'}}
                                            fullWidth
                                    >
                                        <ArrowBackIosIcon style={{marginLeft: '5'}}/>
                                    </Button>
                                </MobileView>
                                <BrowserView style={{width: '100%'}}> <Button disabled={page === 0} color='inherit'
                                                                              size='medium' variant="outlined"
                                                                              startIcon={<ArrowBackIosIcon/>}
                                                                              onClick={handlePrevButton}
                                                                              style={{height: '40px'}}
                                                                              fullWidth
                                >{!isMobile && 'PREV'}</Button>
                                </BrowserView>
                            </Grid>
                            <Grid xs={7} textAlign={'center'}>
                                <Typography>
                                    {page + 1} page
                                </Typography>
                            </Grid>
                            <Grid xs>
                                <MobileView>
                                    <Button color='inherit' size='medium' variant="outlined" onClick={handleNextButton}
                                            style={{height: '40px'}}
                                            fullWidth
                                    >
                                        <ArrowForwardIosIcon/>
                                    </Button>
                                </MobileView>
                                <BrowserView style={{width: '100%'}}>
                                    <Button color='inherit' size='medium' variant="outlined"
                                            startIcon={<ArrowForwardIosIcon/>} onClick={handleNextButton}
                                            style={{height: '40px'}}
                                            fullWidth
                                    >{'PREV'}</Button>
                                </BrowserView>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                {/*<Copyright/>*/}

                <Typography variant="body2" color="text.secondary" align="center"
                            sx={{
                                mt: 1
                            }}
                >
                    <span onDoubleClick={handleClickCopyright}>{'Copyright © '}</span>
                    <Link color="inherit" href="http://donnert.net/">
                        donnert.net
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
                <Dialog
                    // fullScreen={fullScreen}
                    fullWidth={true}
                    open={open}
                    onClose={handleCloseDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent style={{
                        height: '60vh',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingLeft: '12px',
                        paddingRight: '12px'
                    }}>
                        {newChat &&
                            <ChatLogin ref={chatRef}/>
                        }
                        {!newChat &&
                            <iframe src={"chat.html"}
                                    style={{
                                        overflow: "hidden",
                                        height: "100%",
                                        width: "100%"
                                    }} height="100%" width="100%"
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCloseDialog}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
            {/* </ThemeProvider>*/}
        </>
    );
}

export default Main;