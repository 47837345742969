import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Grid,
    Avatar,
    Box,
    Button,
    Container,
    Paper,
    TextField,
    Typography,
    Fab, Divider, IconButton, MenuItem, Menu, ListItemIcon, Link, useTheme, Tooltip
} from "@mui/material";
import React, {ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import * as StompJs from "@stomp/stompjs";
import {Client, CompatClient, IMessage} from "@stomp/stompjs";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;
import SendIcon from '@mui/icons-material/Send';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
// import ReactTimeAgo from "react-time-ago";
import TimeAgo from 'react-timeago'
import {v4 as uuidv4} from 'uuid';
import {Logout, PersonAdd, Settings, Shuffle, ShuffleOn} from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import apiInstance from "../common/ApiInstance";
import axios from "axios";
import {ToastContainer, toast} from 'react-toastify';

export type ChatProps = {
    disconnect: () => void
}

// const ChatLogin = () => {
const ChatLogin = forwardRef<ChatProps>((props, ref) => {
    const theme = useTheme();
    const [textFieldValue, setTextFieldValue] = useState<string>('');

    let uuid = localStorage.getItem("chatUuid")
    let chatColor = localStorage.getItem("chatColor") || '#a5d6a7'
    const client = useRef<Client>()
    const [chatUsername, setChatUsername] = useState<string>('');
    const [messages, setMessages] = useState<any[]>([]);
    const [showLogin, setShowLogin] = useState<boolean>(true);
    const [showChat, setShowChat] = useState<boolean>(false);
    const [inputMessage, setInputMessage] = useState<string>('');

    // 메뉴
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // file upload
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const [filename, setFilename] = useState("");
    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[e.target.files.length - 1];
        const {name} = file;
        setFilename(name);

        const formData = new FormData();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        formData.append('multipartFiles', file);
        try {
            axios.post("/api/chat/file", formData, config).then((it) => {
                client.current!.publish({
                    destination: '/app/chat.sendMessage',
                    body: JSON.stringify({
                        uuid: uuid,
                        chatColor: chatColor,
                        sender: chatUsername,
                        content: document.location.origin + '/static/data/' + it.data.filename,
                        type: 'CHAT'
                    })
                })
            })
        } catch (error) {
            toast('error:' + error)
        }
    };


    React.useEffect(() => {
        try {
            if (uuid === null || chatColor == null) {
                uuid = uuidv4()
                setRandomColor()
                localStorage.setItem("chatUuid", uuid)
            }
        } catch (e) {
            toast('error:' + e)
        }
        const localStorageChatUsername = localStorage.getItem("chatUsername")
        const username = (localStorageChatUsername === '' || localStorageChatUsername === null || localStorageChatUsername === undefined) ? 'USER' : localStorageChatUsername
        setChatUsername(username)
        if (username !== 'USER') {
            // clickLoginButton()
            connect(username)
        }
    }, [])
    React.useEffect(() => {
        localStorage.setItem("chatUsername", chatUsername)
    }, [chatUsername])

    // 부모 컴포넌트에서 사용할 수 있는 함수들을 내부에 표기
    useImperativeHandle(ref, () => ({
        disconnect
    }));

    const setRandomColor = () => {
        const colors = ['#339a40', '#a5d6a7', '#90caf9', '#f48fb1',
            '#ffcc80', '#80cbc4', '#b39ddb', '#c5a762']; // Add more colors as needed
        const randomIndex = Math.floor(Math.random() * colors.length);

        const random = colors[randomIndex]

        localStorage.setItem("chatColor", random)
        chatColor = random

        return random
    };

    const shuffleColor = () => {
        const random = setRandomColor()
        const convertedMessages = messages.map((item) => {
            if (item.uuid === uuid && item.type !== 'JOIN' && item.type !== 'LEAVE') {
                item.chatColor = random
            }
            return item
        })

        setMessages(convertedMessages)
        client.current!.publish({
            destination: '/app/chat.setMessages',
            body: JSON.stringify(convertedMessages)
        })
    }

    const clickLoginButton = () => {
        setMessages([])
        connect()
    }
    const disconnect = () => {
        client.current?.deactivate()
    }

    function sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const connect = (username: string = chatUsername) => {
        sleep(1000)
        const wsHost = document.location.host.startsWith('localhost') ? "localhost:8080" : document.location.host
        const wsProtocol = document.location.protocol == 'http:' ? "ws://" : "wss://"
        // 소켓 연결
        try {
            client.current = new StompJs.Client({
                brokerURL: wsProtocol + wsHost + "/ws",
                onConnect: () => {
                    toast('Connected')
                    subscribe(username); // 연결 성공 시 구독하는 로직 실행
                    setShowLogin(false)
                    setShowChat(true)
                },
            });

            client.current.activate(); // 클라이언트 활성화
        } catch (err) {
            console.log(err);
            toast('err' + err)
        }
    };

    const subscribe = (username: string | null) => {
        console.log("Subscribe")
        client.current!.subscribe('/topic/public', (body: IMessage) => {
            const json_body = JSON.parse(body.body);
            // setTextFieldValue(body.body)
            // let addMessage: any[] = []
            // if (json_body instanceof Array) {
            //     const lastOne = (json_body as Array<any>).at(-1)
            //
            //     // 마지막 들어온게 다른사람이면 입장메세지만
            //     // 나면 마지막 5개
            //     if (lastOne.uuid !== uuid) {
            //         addMessage.push(lastOne)
            //     } else {
            //         addMessage.push(...json_body)
            //     }
            // } else {
            //     addMessage.push(json_body)
            // }
            // addMessage.forEach((one) => {
            //     if (one.type === 'JOIN') {
            //         one.content = one.sender + '님이 들어왔습니다'
            //         one.chatColor = '#000000'
            //     }
            //     if (one.type === 'LEAVE') {
            //         one.content = one.sender + '님이 나갔습니다'
            //         one.chatColor = '#000000'
            //     }
            // })

            // console.log(messages)
            // const newMessages = [
            //     ...messages, ...json_body
            // ]
            // console.log(newMessages)
            // if (newMessages.length > 10) {
            //     newMessages.splice(0, newMessages.length - 10)
            // }
            // console.log(newMessages)
            // setMessages(newMessages)
            // console.log(messages)


            setMessages((chats) => {
                    const newMessage = [...chats, ...json_body]

                    if (newMessage.length > 50) {
                        newMessage.splice(0, newMessage.length - 50)
                    }
                    return newMessage
                }
            )

            // setMessages( (chats) => [
            //     ...chats, ...((json_body instanceof Array) ? json_body : [json_body])
            //     ]);
        });

        client.current!.publish({
            destination: '/app/chat.addUser',
            body: JSON.stringify({uuid: uuid, chatColor: chatColor, sender: username, type: 'JOIN'})
        })

    }

    const handleSendMessage = () => {
        if (inputMessage === '') {
            return
        }
        setInputMessage('')
        client.current!.publish({
            destination: '/app/chat.sendMessage',
            body: JSON.stringify({
                uuid: uuid,
                chatColor: chatColor,
                sender: chatUsername,
                content: inputMessage,
                type: 'CHAT'
            })
        })
    }
    const scrollRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }


    }, [messages]);

    const handleClickLink = ((e: React.MouseEvent<HTMLElement>) => {
        // viewFile("www.naver.com")
        // const host = document.location.host.startsWith('localhost') ? "localhost:8080" : "ddanzit.donnert.net"
        const url = e.currentTarget.textContent!
        window.open(url, '_blank')
    })

    const changeUsername = () => {
        disconnect()
        setShowLogin(true)
        setShowChat(false)
    }
    const timeForToday = (value: any) => {
        const today = new Date();
        const timeValue = new Date(value);

        const betweenTime = Math.floor(
            (today.getTime() - timeValue.getTime()) / 1000 / 60
        );
        if (betweenTime < 1) return "방금전";
        if (betweenTime < 180) {
            return `${betweenTime}분전`;
        }

        const betweenTimeHour = Math.floor(betweenTime / 60);
        if (betweenTimeHour < 24) {
            return `${betweenTimeHour}시간전`;
        }

        const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
        if (betweenTimeDay < 365) {
            return `${betweenTimeDay}일전`;
        }

        return `${Math.floor(betweenTimeDay / 365)}년전`;
    };
    return (
        <>
            {showLogin &&
                <Container component="main" maxWidth="xs">
                    <Paper elevation={3} style={{padding: '20px', marginTop: '20px'}}>
                        <Typography variant="h5">딴짓</Typography>
                        <form>
                            <TextField
                                label="Nickname"
                                variant="outlined"
                                margin="normal"
                                value={chatUsername}
                                fullWidth
                                onChange={(e) => setChatUsername(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={clickLoginButton}
                            >
                                Login
                            </Button>
                        </form>
                    </Paper>
                </Container>
            }

            {showChat &&
                <Box
                    style={{
                        height: '100%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {/*<TextField size="small" rows={2} fullWidth={true} multiline={true}*/}
                    {/*           value={JSON.stringify(messages)}/>*/}
                    <input ref={fileInputRef} type="file" hidden onChange={handleFileUpload}/>
                    <Paper elevation={1} style={{
                        flex: 1,
                        padding: '5px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        whiteSpace: 'pre-wrap'
                    }}>
                        {messages.map((message) => (
                            <Box sx={{
                                bgcolor: (message.uuid === uuid) ? 'text.disabled' : ''
                            }}>
                                <div style={{
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                }}>
                                    {
                                        (message.type === 'JOIN') ? (
                                            <div style={{textAlign: 'center'}}>
                                                <Tooltip title={timeForToday(message.date)} placement={'left'}
                                                         sx={{width: '100%'}}>
                                                    <Typography variant="caption" display={"inline"}
                                                                sx={{fontWeight: 'bold'}}
                                                                style={{
                                                                    textAlign: "center",
                                                                    width: "100%"
                                                                }}>
                                                        {message.sender}님이 들어왔습니다
                                                    </Typography>
                                                </Tooltip>
                                            </div>
                                        ) : (message.type === 'LEAVE') ? (
                                            <div style={{textAlign: 'center'}}>
                                                <Tooltip title={timeForToday(message.date)} placement={'left'}>
                                                    <Typography variant="caption" display={"inline"}
                                                                sx={{fontWeight: 'light'}}>
                                                        {message.sender}님이 나갔습니다
                                                    </Typography>
                                                </Tooltip>
                                            </div>
                                        ) : (message.type === 'CHAT') ? (
                                            <React.Fragment>
                                                <Tooltip title={timeForToday(message.date)} placement={'left'}>
                                                    <Typography variant={"body2"} gutterBottom display={"inline"}
                                                                sx={{fontWeight: 'bold'}}>
                                                        {message.sender}:
                                                    </Typography>
                                                </Tooltip>
                                                {(message.content && message.content.startsWith('http')) ? (
                                                    <Link
                                                        style={{cursor: 'pointer', wordBreak: 'break-all'}}
                                                        onClick={handleClickLink}>
                                                        {message.content}
                                                    </Link>) : <Typography display="inline" variant="body2"
                                                                           gutterBottom>{message.content}</Typography>
                                                }
                                            </React.Fragment>
                                        ) : <div/>
                                    }
                                </div>
                            </Box>
                        ))}
                        <div ref={scrollRef}></div>
                    </Paper>
                    <Box style={{marginTop: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                        <TextField
                            variant="outlined"
                            fullWidth
                            rows={1}
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            style={{marginRight: '10px', flex: 1, height: '40px'}} // 높이 조절을 위한 스타일 추가
                            InputProps={{style: {height: '100%'}}} // InputProps에 높이 조절을 위한 스타일 추가
                            onKeyUp={(ev) => {
                                if (ev.key === 'Enter' && !ev.nativeEvent.isComposing) {
                                    ev.preventDefault();
                                    handleSendMessage()
                                }
                            }}
                        />
                        <IconButton aria-label="send" onClick={handleSendMessage}>
                            <SendIcon/>
                        </IconButton>
                        <IconButton aria-label={"파일업로드"} onClick={() => fileInputRef.current!.click()}>
                            <UploadFileIcon/>
                        </IconButton>
                        <IconButton aria-label={"이름변경"} onClick={() => {
                            handleMenuClose()
                            changeUsername()
                        }}>
                            <DriveFileRenameOutlineIcon/>
                        </IconButton>
                        {/*<Grid item xs={1} style={{alignItems: "right"}}>*/}
                        {/*    <IconButton*/}
                        {/*        aria-label="more"*/}
                        {/*        id="long-button"*/}
                        {/*        aria-controls={menuOpen ? 'long-menu' : undefined}*/}
                        {/*        aria-expanded={menuOpen ? 'true' : undefined}*/}
                        {/*        aria-haspopup="true"*/}
                        {/*        onClick={handleMenuClick}*/}
                        {/*    >*/}
                        {/*        <MoreVertIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*    <Menu*/}
                        {/*        anchorEl={anchorEl}*/}
                        {/*        id="account-menu"*/}
                        {/*        open={menuOpen}*/}
                        {/*        onClose={handleMenuClose}*/}
                        {/*        onClick={handleMenuClose}*/}
                        {/*    >*/}
                        {/*        <MenuItem onClick={() => {*/}
                        {/*            handleMenuClose()*/}
                        {/*            changeUsername()*/}
                        {/*        }}>*/}
                        {/*            <ListItemIcon>*/}
                        {/*                <DriveFileRenameOutlineIcon fontSize="small"/>*/}
                        {/*            </ListItemIcon>*/}
                        {/*            이름변경*/}
                        {/*        </MenuItem>*/}
                        {/*    </Menu>*/}
                        {/*</Grid>*/}
                    </Box>
                </Box>
            }
        </>
    )
})
export default ChatLogin;
